<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!-- <h2 class="title">
        <div class="title_icon">
        </div>
        {{isEdit ? '修改' : '添加'}}预约商品
      </h2>-->
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="submitData">
        <p class="subtitle">基础信息</p>
        <div v-if="isEdit">
          <el-form label-position="right" label-width="110px">
            <el-row>
              <el-col :span="20">
                <el-form-item :label="$t('searchModule.Merchant_Name')" style="width: 320px">
                  <span>{{ formInline.operationName }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <i
                  class="el-icon-circle-plus-outline newTime"
                  @click="newItem"
                />
              </el-col>
            </el-row>
            <el-form-item :label="$t('searchModule.park_name')">
              <span>{{ modelvalue }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div v-else>
          <el-form
            label-position="right"
            label-width="110px"
            :model="formInline"
            :rules="rules"
            ref="form"
          >
            <el-row>
              <el-col :span="20">
                <el-form-item
                  :label="$t('searchModule.Merchant_Name')"
                  prop="operationId"
                  style="width: 320px"
                >
                  <el-select
                    v-model="formInline.operationId"
                    filterable
                    size="15"
                    @change="merchantChange"
                  >
                    <el-option
                      :label="value.operationName"
                      :value="value.operationId"
                      v-for="value in merchantList"
                      :key="value.operationId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <i
                  class="el-icon-circle-plus-outline newTime"
                  @click="newItem"
                />
              </el-col>
            </el-row>
            <el-form-item :label="$t('searchModule.park_name')" prop="parkId">
              <el-autocomplete
                class="inline-input"
                v-model="modelvalue"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入车场名称"
                value-key="parkName"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
          </el-form>
        </div>
        <p class="subtitle" v-show="staggeredList.length || detailList.length">
          销售信息
        </p>
        <div class="detail-box">
          <div
            class="detail-item"
            v-for="(item, index) in detailList"
            :key="index"
          >
            <el-form label-position="right" label-width="110px">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="错峰卡名称">
                    <span>{{ item.staggeredName }}</span>
                  </el-form-item></el-col
                >
                <el-col :span="4">
                  <i
                    class="el-icon-circle-close removeTime"
                    @click="removeItem(index, item)"
                  />
                </el-col>
              </el-row>
              <el-form-item label="错峰日期" style="width: 660px">
                <span>{{ item.rangeDate[0] }}</span> 至
                <span>{{ item.rangeDate[1] }}</span>
              </el-form-item>
              <el-form-item label="错峰时间" style="width: 660px">
                <span>{{ item.rangeTime[0] }}</span> -
                <span>{{ item.rangeTime[1] }}</span>
              </el-form-item>
              <el-form-item label="星期配置">
                <span>
                  <span v-for="(week, index) in item.weeks" :key="week">
                    周{{ ["一", "二", "三", "四", "五", "六", "日"][week - 1] }}
                    <span v-if="index < item.weeks.length - 1">、</span>
                  </span>
                </span>
              </el-form-item>
              <el-form-item style="width: 800px" label="错峰卡数量">
                <span>{{ item.berthNumber }}</span>
                <span>个</span>
              </el-form-item>
              <el-form-item style="width: 800px" label="销售价格">
                <span>{{ item.actualMoney }}</span>
                <span>&nbsp;&nbsp;元/天</span>
              </el-form-item>
              <el-form-item style="width: 800px" label="错峰说明" prop="remark">
                <span>{{ item.remark }}</span>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div
          class="info-box"
          :class="{ hasTopBorder: detailList.length }"
          v-show="staggeredList.length"
        >
          <div
            class="info-item"
            v-for="(item, index) in staggeredList"
            :key="index"
          >
            <el-form
              label-position="right"
              label-width="110px"
              :model="item"
              :rules="rules"
              ref="form-item"
            >
              <el-row>
                <el-col :span="20">
                  <el-form-item label="错峰卡名称" prop="staggeredName">
                    <el-input
                      v-model="item.staggeredName"
                      placeholder="请输入错峰卡名称"
                      style="width: 210px"
                      :rules="rules.staggeredName"
                      maxlength="80"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <i
                    class="el-icon-circle-close removeTime"
                    @click="removeItem(index, item)"
                  />
                </el-col>
              </el-row>
              <!-- <el-form-item
                label="错峰时间"
                style="width: 660px"
                prop="rangeTime"
                :rules="rules.rangeTime"
              >
                <el-time-picker
                  v-model="item.rangeTime"
                  is-range
                  range-separator="-"
                  value-format="HH:mm"
                  format="HH:mm"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                ></el-time-picker>
              </el-form-item> -->
              <el-form-item
                label="错峰日期"
                style="width: 660px"
                prop="rangeDate"
                :rules="rules.rangeDate"
              >
                <el-date-picker
                  v-model="item.rangeDate"
                  type="daterange"
                  range-separator="-"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <div class="timeDer">
                <el-form-item
                  label="错峰时间"
                  prop="startTime"
                  style="width: 300px"
                  :rules="rules.startTime"
                >
                  <el-time-select
                    style="z-index: 99"
                    placeholder="起始时间"
                    v-model="item.startTime"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:59',
                    }"
                  ></el-time-select>
                </el-form-item>
                <!-- maxTime: item.endTime, -->
                <!-- minTime: item.startTime, -->

                <div class="timeDerEnds">
                  <el-form-item
                    label="-"
                    prop="endTime"
                    class="timeDerEnd"
                    :rules="rules.endTime"
                  >
                    <el-time-select
                      placeholder="结束时间"
                      v-model="item.endTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59',
                      }"
                    ></el-time-select>
                  </el-form-item>
                </div>
              </div>
              <el-form-item label="星期配置">
                <el-checkbox-group v-model="item.weeks">
                  <el-checkbox label="1">周一</el-checkbox>
                  <el-checkbox label="2">周二</el-checkbox>
                  <el-checkbox label="3">周三</el-checkbox>
                  <el-checkbox label="4">周四</el-checkbox>
                  <el-checkbox label="5">周五</el-checkbox>
                  <el-checkbox label="6">周六</el-checkbox>
                  <el-checkbox label="7">周日</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                style="width: 800px"
                label="错峰卡数量"
                prop="berthNumber"
                :rules="rules.berthNumber"
              >
                <el-input
                  type="text"
                  v-model="item.berthNumber"
                  style="width: 80px"
                  maxlength="7"
                ></el-input>
                <span>个</span>
              </el-form-item>
              <el-form-item
                style="width: 800px"
                label="销售价格"
                prop="actualMoney"
                :rules="rules.actualMoney"
              >
                <el-input
                  type="text"
                  v-model="item.actualMoney"
                  style="width: 210px"
                  maxlength="7"
                ></el-input>
                <span>&nbsp;&nbsp;元/天</span>
              </el-form-item>
              <el-form-item style="width: 800px" label="错峰说明" prop="remark">
                <el-input
                  type="textarea"
                  v-model="item.remark"
                  maxlength="200"
                  show-word-limit
                  style="width: 400px"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
          <el-button type="primary" @click="submitData" style="width: 88px"
            >提交</el-button
          >
          <el-button type @click="$router.go(-1)" style="width: 88px"
            >取消</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "staggerdManageAE",
  data() {
    let parkList = (rule, value, callback) => {
      if (this.value.length == 0) {
        callback(new Error("请选择停车场"));
      } else {
        callback();
      }
    };
    // 0和正整数
    let checkNumber = (rule, value, callback) => {
      if (!/^([1-9]\d*|[0]{1,1})$/.test(value)) {
        callback(new Error("请输入0或正整数"));
      } else {
        callback();
      }
    };
    // 单选校验
    let radio = (rule, value, callback) => {
      if (this.radio == "2") {
        if (this.formInline.appointmentBerthNumber == "") {
          callback(new Error("请输入预约泊位数量"));
        } else if (
          !/(^[1-9]\d*$)/.test(this.formInline.appointmentBerthNumber)
        ) {
          callback(new Error("请输入正整数"));
        } else if (this.formInline.appointmentBerthNumber > this.num) {
          callback(new Error("预约泊位数量最大为" + this.num));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 正整数
    let checkTime2 = (rule, value, callback) => {
      if (!/(^[1-9]\d*$)/.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    // 小数点2位及正数
    let checkTime3 = (rule, value, callback) => {
      if (!/^\d+\.?\d{0,2}$/.test(value)) {
        callback(new Error("请输入0或最多小数点后保留2位的正数"));
      } else {
        callback();
      }
    };
    return {
      modelvalue: "",
      isEdit: false,
      parkData: [],
      value: [],
      loading: false,
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      timeList: [{ appointmentStartDate: "", appointmentEndDate: "" }],
      merchantList: [],
      num: 0,
      formInline: {
        operationId: "",
        parkId: "",
      },
      staggeredList: [],
      detailList: [],
      formInline1: {},
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        parkId: [
          {
            required: true,
            message: "请填写车场名称",
            trigger: "blur change",
          },
        ],
        rangeDate: [
          {
            required: true,
            message: "请选择错峰日期",
            trigger: "blur change",
          },
        ],
        staggeredName: [
          {
            required: true,
            message: "请输入错峰卡名称",
            trigger: "blur change",
          },
        ],
        // rangeTime: [
        //   {
        //     required: true,
        //     message: "请选择错峰时间",
        //     trigger: "blur change",
        //   },
        // ],
        startTime: [
          {
            required: true,
            message: "请选择错峰开始时间",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择错峰结束时间",
            trigger: "blur",
          },
        ],
        berthNumber: [
          {
            required: true,
            // message: '请填写错峰卡数量',
            validator: checkTime2,
            trigger: "blur",
          },
        ],
        actualMoney: [
          {
            required: true,
            // message: '请填写销售价格',
            validator: checkTime3,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 获取商户列表
    getmerchant() {
      let opt = {
        method: "get",
        url: "/acb/2.0/operation/nameList",
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          this.merchantList = res.value;
        }
      });
    },
    parkNameChange(item) {
      if (item.length > 0) {
        let arr = [];
        for (let i = 0; i < this.parkData.length; i++) {
          for (let j = 0; j < item.length; j++) {
            if (item[j] == this.parkData[i].key) {
              arr.push(this.parkData[i].num);
            }
          }
        }
        arr = arr.sort();
        this.num = arr[0];
        this.formInline.parkId = item.toString();
      }
    },
    // 商户改变获取车场
    merchantChange(item) {
      this.loading = true;
      this.formInline.operationId = item;
      let opt = {
        method: "get",
        url: "/acb/2.0/systems/loginUser/parkNameList",
        data: {
          operationId: item,
          parkTypes: 3,
          slaveRelations: 0,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          if (res.value.length == 0) {
            this.$alert("该商户没有停车场", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            const data = [];
            res.value.forEach((city, index) => {
              data.push({
                label: city.parkName,
                key: city.parkId,
                pinyin: city.parkName[index],
                num: city.amount,
              });
            });
            this.parkData = data;
            this.loading = false;
            this.modelvalue = "";
            let arr = [];
            // console.log(this.parkData);
            for (let i = 0; i < this.parkData.length; i++) {
              arr.push(this.parkData[i].num);
            }
            arr = arr.sort();
            this.num = arr[0];
          }
        } else {
          this.loading = false;
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });

      // getDt(opt);
    },
    /* 停车场 */
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/parkNameList";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            parkTypes: "3,4",
            dataSource: "2",
            slaveRelations: "0,1",
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.length
              ? res.value[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 提交
    submitData() {
      // 判断错峰日期是否跨天，如果不是跨天，结束日期必须大于开始日期
      let flag = this.checkData();
      const obj = this.staggeredList.map((x) => {
        if (x.rangeDate[0] === x.rangeDate[1]) {
          if (
            new Date(`${x.rangeDate[0]} ${x.startTime}`) >
            new Date(`${x.rangeDate[1]} ${x.endTime}`)
          ) {
            this.$message.error("当天日期，开始时间必须小于结束时间");
            flag = false;
          }
        }
        return {
          actualMoney: x.actualMoney * 100,
          startDate: x.rangeDate[0],
          endDate: x.rangeDate[1],
          // startTime: x.rangeTime[0],
          // endTime: x.rangeTime[1],
          startTime: x.startTime,
          endTime: x.endTime,
          weeks: x.weeks.sort().join(),
          berthNumber: x.berthNumber,
          remark: x.remark,
          staggeredName: x.staggeredName,
        };
      });
      if (!flag) return;
      let url = `/acb/2.0/staggered/batchAdd?parkId=${this.formInline.parkId}`;
      this.$axios
        .post(
          url,
          {
            data: obj,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.state == 0) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            this.$router.go(-1);
          }
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        });
    },
    //  获取详情
    workDetail() {
      this.$axios
        .get("/acb/2.0/staggeredPark/detail", {
          data: {
            staggeredParkId: this.staggeredParkId,
          },
        })
        .then((res) => {
          // this.merchantChange(res.value.operationId)
          this.formInline.parkId = res.value.parkId;
          this.formInline.operationName = res.value.operationName;
          this.modelvalue = res.value.parkName;
          this.detailList = res.value.staggeredList.map((x) => {
            return {
              ...x,
              rangeDate: [x.startDate, x.endDate],
              rangeTime: [x.startTime, x.endTime],
              weeks: x.weeks.split(","),
              berthNumber: x.berthNumber,
              actualMoney: x.actualMoney / 100,
              remark: x.remark,
            };
          });
        });
    },
    // 提交校验
    checkData() {
      let flag = true;
      // 验证上部运营商和车场表单
      if (!this.isEdit && !this.staggeredList.length) {
        this.$alert("该车场未有错峰商品，无法提交，请创建错峰商品！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      const form = this.$refs["form"];
      if (form) {
        form.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      // 验证下面配置列表的表单
      const formArr = this.$refs["form-item"];
      if (!formArr) return flag;
      if (Array.isArray(formArr)) {
        formArr.forEach((form) => {
          form.validate((valid) => {
            if (!valid) {
              flag = false;
            }
          });
        });
      } else {
        formArr.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      return flag;
    },
    // 新加一栏时间段
    newItem() {
      const obj = {
        rangeDate: "",
        // rangeTime: "",
        startTime: "",
        endTime: "",
        weeks: ["1", "2", "3", "4", "5", "6", "7"],
        berthNumber: "",
        actualMoney: "",
        remark: "",
      };
      this.staggeredList.push(obj);
    },
    // 删除一栏指定时间段
    removeItem(index, data) {
      // console.log(data);
      if (data.staggeredId) {
        this.deleteItem(data.staggeredId, index);
      } else {
        this.staggeredList.splice(index, 1);
      }
    },
    // 删除下架下架错峰配置
    deleteItem(staggeredId, index) {
      this.$axios
        .post("/acb/2.0/staggered/down", {
          data: {
            staggeredId,
          },
        })
        .then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.detailList.splice(index, 1);
        });
    },
  },
  created() {
    this.getmerchant();
  },
  mounted() {
    if (this.$route.query.staggeredParkId) {
      this.staggeredParkId = this.$route.query.staggeredParkId;
      this.workDetail();
      this.isEdit = true;
    } else {
      this.newItem();
    }
  },
};
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.subtitle {
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  line-height: 35px;
  background: rgb(204, 204, 204);
  padding-left: 20px;
  margin-bottom: 20px;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}

.newTime {
  color: #0099fa;
  font-size: 38px;
  cursor: pointer;
  position: relative;
  left: 100px;
}

.removeTime {
  color: red;
  font-size: 38px;
  cursor: pointer;
  position: relative;
  left: 100px;
}

.info-item {
  border-bottom: 1px solid #ccc;
  margin: 30px 0;

  &:last-child {
    border: none;
  }
}

.detail-item {
  border-bottom: 1px solid #ccc;
  margin: 30px 0;

  &:last-child {
    border: none;
  }
}

.hasTopBorder {
  border-top: 1px solid #ccc;
}

.timeDer {
  position: relative;

  .timeDerEnds .timeDerEnd {
    position: absolute;
    top: 0px;
    left: 260px;
  }
}
</style>
<style>
.timeDer
  .timeDerEnds
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  margin-right: 0px;
}
</style>
